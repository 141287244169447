<script setup lang="ts">
import { type LanguageOption, languageOptions } from '@/types/language-options.js';

const props = defineProps<{
  sourceLanguage: LanguageOption;
  targetLanguage: LanguageOption;
}>();

const emit = defineEmits(['update:sourceLanguage', 'update:targetLanguage']);

function updateSelection(id: string, selected: 'sourceLanguage' | 'targetLanguage') {
  const option = languageOptions.find((o) => o.id === id)!;
  emit(`update:${selected}`, option);
}
</script>

<template>
  <div class="language-selects">
    <v-select
      :items="languageOptions"
      :model-value="props.sourceLanguage"
      item-value="id"
      item-title="label"
      label="Quellsprache"
      outlined
      @update:model-value="updateSelection($event, 'sourceLanguage')"
    />
    <v-select
      :items="languageOptions"
      :model-value="props.targetLanguage"
      item-value="id"
      item-title="label"
      label="Zielsprache"
      outlined
      @update:model-value="updateSelection($event, 'targetLanguage')"
    />
  </div>
</template>

<style scoped lang="scss">
.language-selects {
  display: flex;
  gap: 40px;
  justify-content: start;
  align-items: center;

  & > * {
    flex-grow: 0;
  }
}
</style>
