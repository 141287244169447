<script setup lang="ts">
const props = defineProps<{
  excludeTranslated: boolean;
  excludeEmpty: boolean;
  excludeExternal: boolean;
  excludeInternal: boolean;
}>();

const emit = defineEmits([
  'update:excludeTranslated',
  'update:excludeInternal',
  'update:excludeExternal',
  'update:excludeEmpty',
]);
</script>

<template>
  <div class="localization-filters">
    <v-checkbox
      :model-value="props.excludeInternal"
      label="Nichtübersetzbare ausblenden"
      @update:model-value="emit('update:excludeInternal', $event)"
    ></v-checkbox>
    <v-checkbox
      :model-value="props.excludeExternal"
      label="Übersetzbare ausblenden"
      @update:model-value="emit('update:excludeExternal', $event)"
    ></v-checkbox>
    <v-checkbox
      :model-value="props.excludeTranslated"
      label="Übersetzte ausblenden"
      @update:model-value="emit('update:excludeTranslated', $event)"
    ></v-checkbox>
    <v-checkbox
      :model-value="props.excludeEmpty"
      label="Leere ausblenden"
      @update:model-value="emit('update:excludeEmpty', $event)"
    ></v-checkbox>
  </div>
</template>

<style scoped lang="scss">
.localization-filters {
  display: flex;
  gap: 40px;
  justify-content: start;
  align-items: center;

  & > * {
    flex-grow: 0;
  }
}
</style>
