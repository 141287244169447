import App from './App.vue';
import { createApp } from 'vue';
import { useKeycloak } from '@/plugins/keycloak.js';
import { loadFonts } from '@/plugins/webfontloader.js';
import vuetify from '@/plugins/vuetify.js';
import router from '@/router/index.js';
import '@/styles/variables.css';

if (!import.meta.env.VITE_AUTH_SERVER_URL) {
  throw Error('"import.meta.env.VITE_AUTH_SERVER_URL" not found');
}

const { token } = useKeycloak();
if (token) {
  const app = createApp(App);

  loadFonts();
  app.use(vuetify);
  app.use(router);

  app.mount('#app');
} else {
  window.location.reload();
}
