export enum LANGUAGE_KEY {
  GERMAN = 'de',
  ENGLISH = 'en',
  ITALIAN = 'it',
  FRENCH = 'fr',
  POLISH = 'pl',
  CHINESE = 'zh',
  SPANISH = 'es',
  CZECH = 'cs',
}
export type Localization = Partial<
  Record<LANGUAGE_KEY, string> & {
    excludeFromTranslation: boolean;
  }
>;

export interface LocalizationServiceResponse {
  [key: string]: Localization | LocalizationServiceResponse;
}
