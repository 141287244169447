<script setup lang="ts">
import textLocalization from '@/apiConnections/text-localization.js';
import { MESSAGE_TYPE, MessageService } from '@/shared/message-service';
import { type LanguageData } from '@/types/language-data.js';
import type { LOCALE } from '@/types/language-options.js';
import { type LanguageOption, languageOptions } from '@/types/language-options.js';
import { saveAs } from 'file-saver-es';
import JSZip from 'jszip';
import { ref, type Ref } from 'vue';

const props = defineProps<{
  appId: string | null;
  disabled: boolean;
  selected: LanguageData['id'][];
  sourceLanguageId: LOCALE;
}>();

const isDownloadDialogShown = ref(false);
const selectedLanguages: Ref<LanguageOption['id'][]> = ref([]);

async function downloadTranslations() {
  const dateString = new Intl.DateTimeFormat('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(new Date());
  const dateStringFormatted = `${dateString.split('.')[2]}_${dateString.split('.')[1]}_${dateString.split('.')[0]}`;
  const appIdFormatted = props.appId!.replace(/-/g, '_');

  const messageService = MessageService.getInstance();

  if (selectedLanguages.value.length === 1) {
    // single language selected --> download .xlf
    const targetLangId = selectedLanguages.value[0];
    createXliff(targetLangId, appIdFormatted, dateStringFormatted)
      .then((XliffString) => {
        const translationFile = new Blob([XliffString.content], { type: 'application/xliff+xml' });
        saveAs(translationFile, XliffString.fileName);
        messageService.add({
          text: 'XLF wird heruntergeladen.',
          type: MESSAGE_TYPE.INFO,
        });
      })
      .catch(() => {
        messageService.add({
          text: 'XLF konnte nicht erstellt werden.',
          type: MESSAGE_TYPE.ERROR,
        });
      });
  } else {
    // multiple languages selected --> create .zip
    const zip = new JSZip();
    const exportedLanguages = selectedLanguages.value.join('_');
    await Promise.all(
      selectedLanguages.value.map(async (targetLangId) => {
        await createXliff(targetLangId, appIdFormatted, dateStringFormatted)
          .then((singleXML) => {
            zip.file(singleXML.fileName, singleXML.content);
          })
          .catch(() => {
            messageService.add({
              text: `XLF konnte für ausgewählte Sprache '${targetLangId}' nicht erstellt werden.`,
              type: MESSAGE_TYPE.ERROR,
            });
          });
      }),
    );
    if (Object.keys(zip.files).length) {
      const archive = await zip.generateAsync({ type: 'blob' });
      saveAs(archive, `${appIdFormatted}-${exportedLanguages.replace(/-/g, '_')}-${dateStringFormatted}-DS.zip`);
      messageService.add({
        text: 'zip-Archiv wird heruntergeladen.',
        type: MESSAGE_TYPE.INFO,
      });
    } else {
      // error
      messageService.add({
        text: 'zip-Archiv konnte nicht erstellt werden.',
        type: MESSAGE_TYPE.ERROR,
      });
    }
  }

  isDownloadDialogShown.value = false;
}

async function createXliff(targetLangId: string, appIdFormatted: string, dateStringFormatted: string) {
  if (!props.appId) throw Error('No app selected.');

  const paths = { appId: props.appId, subPaths: [] as string[] };
  props.selected.forEach((id) => {
    paths.subPaths.push(id.substring(0, id.length - 1));
  });
  const fileName = `${appIdFormatted}-${props.sourceLanguageId.replace(/-/g, '_')}-${targetLangId.replace(/-/g, '_')}-${dateStringFormatted}-DS.xlf`;
  return textLocalization
    .getSelected(paths, props.sourceLanguageId, targetLangId)
    .then((content) => ({ fileName: fileName, content: content }));
}
</script>

<template>
  <v-dialog v-model="isDownloadDialogShown" max-width="800px">
    <template #activator="{ props: activatorProps }">
      <v-btn v-bind="activatorProps" :disabled="disabled">
        <v-icon>mdi-download</v-icon>&nbsp;ausgewählte als XLF ({{ selected.length }})
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Gewünschte Sprachen auswählen</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col v-for="language in languageOptions" :key="language.id" cols="12" sm="6" md="4">
              <v-checkbox
                v-model="selectedLanguages"
                :value="language.id"
                name="lang"
                :label="language.label"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="isDownloadDialogShown = false">Abbrechen</v-btn>
        <v-btn variant="elevated" :disabled="!selectedLanguages.length" @click="downloadTranslations">Download</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss"></style>
