import { USAGE_LOCATION } from '@/types/language-data.js';
import { ObjectPoolData } from '@/apiConnections/object-pool-data.js';

export async function openResource(resource: USAGE_LOCATION, id: string | number) {
  switch (resource) {
    case USAGE_LOCATION.PARAMETERS:
      id = (await ObjectPoolData.getParameter(+id, { fields: 'key' })).key;
      window.open(`${import.meta.env.VITE_OWB_URL}/${resource}/${id}`, 'blank');
      break;
    case USAGE_LOCATION.OBJECTS:
    case USAGE_LOCATION.CONNECTIONS:
      window.open(`${import.meta.env.VITE_OWB_URL}/${resource}/${id}`, 'blank');
      break;
    case USAGE_LOCATION.CATEGORIES:
    case USAGE_LOCATION.FILTERS:
      window.open(import.meta.env.VITE_CDM_URL, 'blank');
      break;
  }
}
