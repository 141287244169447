<script setup lang="ts">
const props = defineProps({ headerTitle: { type: String, required: true } });
</script>

<template>
  <div class="minifiedHeader">
    <div class="ui_components-header-page">
      <div id="ui_components-header">
        <img class="logo" src="https://cdn.item24.com/tools-assets/core/logo.svg" alt="item-logo" />
        <h1 class="name">{{ props.headerTitle }}</h1>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.minifiedHeader {
  height: 4em;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 640px);
  @media only screen and (min-width: 1920px) {
    width: calc(100% - 700px);
  }
  .ui_components-header-page {
    padding: 0 1.5rem;
    border-bottom: 1px solid #ccc;
  }
  #ui_components-header {
    text-align: center;
    display: flex;
    justify-content: center;
  }
  #ui_components-header-profile {
    display: none;
  }
  .logo {
    display: inline-block;
    margin-block-start: 0.7em;
    margin-block-end: 0.5rem;
  }
  .name {
    display: inline-block;
    padding-left: 1rem;
    margin-block-start: 0.7rem;
    margin-block-end: 0.5rem;
    text-transform: uppercase;

    @media only screen and (max-width: 1279px) {
      font-size: 20px;
    }
  }
}
</style>
