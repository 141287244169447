<script setup lang="ts">
import textLocalization from '@/apiConnections/text-localization.js';
import { openResource } from '@/components/Table/open-resource.js';
import { MESSAGE_TYPE, MessageService } from '@/shared/message-service';
import type { USAGE_LOCATION } from '@/types/language-data';
import { type LanguageData } from '@/types/language-data';
import type { LOCALE } from '@/types/language-options.js';
import { LOCALE_TO_KEY } from '@/types/language-options.js';
import { type Localization } from '@/types/localization.js';
import deepClone from 'rfdc';
import { computed, type Ref, ref, watch } from 'vue';

const props = defineProps<{
  appId: string | null;
  items: LanguageData[];
  sourceColHeader: string;
  sourceColKey: LOCALE;
  targetColHeader: string;
  targetColKey: LOCALE;
}>();
const isOpdSelected = computed(() => props.appId === 'object-pool-data');

const emit = defineEmits(['update:selected', 'update:items']);

const search = ref('');
const items = computed(() => {
  return props.items.filter((i) => {
    return search.value?.length ? i.searchContent.toLowerCase().includes(search.value.toLowerCase()) : true;
  });
});

const selected: Ref<LanguageData['id'][]> = ref([]);
watch(items, (items) => (selected.value = items.map((i) => i.id)));
watch(selected, (s) => emit('update:selected', s));

const isEditDialogShown = ref(false);

const editData: Ref<LanguageData> = ref({
  id: '',
  searchContent: '',
  [props.sourceColKey]: '',
  [props.targetColKey]: '',
});

const slotSource = computed(() => {
  return `item.${props.sourceColKey}` as `item.${string}`;
});
const slotTarget = computed(() => {
  return `item.${props.targetColKey}` as `item.${string}`;
});

interface DataTableHeader {
  title: string;
  key: string;
  width?: string;
  value?: string;
  align?: string;
  sortable?: boolean;
}
const headers = computed(() => {
  const headers: DataTableHeader[] = [
    {
      title: '',
      key: 'data-table-select',
      value: 'data-table-select',
      width: '1%',
    },
    {
      title: 'Language-ID',
      align: 'start',
      key: 'id',
      width: '16%',
    },
    {
      title: 'Suchwort',
      align: ' d-none',
      key: 'searchContent',
    },
    {
      title: 'NICHT übersetzen',
      key: 'data-table-do-not-translate',
      value: 'data-table-do-not-translate',
      width: '1%',
    },
    {
      title: '',
      key: 'data-table-edit',
      value: 'data-table-edit',
      width: '1%',
      sortable: false,
    },
    {
      title: props.sourceColHeader,
      key: props.sourceColKey,
      width: '33%',
    },
    {
      title: props.targetColHeader,
      key: props.targetColKey,
      width: '33%',
    },
  ];
  if (isOpdSelected.value)
    headers.push({
      title: 'Verwendet in',
      key: 'usage',
      width: '15%',
    });

  return headers;
});

function showEditDialog(item: LanguageData) {
  editData.value = item;
  isEditDialogShown.value = true;
}
async function saveEdit() {
  const item = props.items.find(({ id }) => id === editData.value.id);
  if (item) {
    const sourceLang = LOCALE_TO_KEY[props.sourceColKey];
    const targetLang = LOCALE_TO_KEY[props.targetColKey];
    const updatedTranslation: Localization = {};
    if (editData.value[props.sourceColKey] !== item[props.sourceColKey]) {
      updatedTranslation[sourceLang] = editData.value[props.sourceColKey] || '';
    }
    if (editData.value[props.targetColKey] !== item[props.targetColKey]) {
      updatedTranslation[targetLang] = editData.value[props.targetColKey] || '';
    }
    if (editData.value.excludeFromTranslation !== item.excludeFromTranslation) {
      updatedTranslation.excludeFromTranslation = editData.value.excludeFromTranslation;
    }

    const messageService = MessageService.getInstance();

    await textLocalization
      .updateSubDoc(`${props.appId}/${editData.value.id}`, updatedTranslation)
      .then(() => {
        Object.assign(item, editData.value);
        item.touched = 'touched';
        item.searchContent = `${editData.value.id} ${item[props.sourceColKey]} ${item[props.targetColKey]}`;
        messageService.add({
          text: 'Bezeichner erfolgreich geändert',
          type: MESSAGE_TYPE.INFO,
        });
        emit('update:items', props.items);
      })
      .catch((error) => {
        messageService.add({
          text: `Update fehlgeschlagen - der text-localization-service hat einen Fehler zurückgegeben: ${error}`,
          type: MESSAGE_TYPE.ERROR,
        });
      });
  }
  isEditDialogShown.value = false;
}
</script>

<template>
  <div>
    <v-data-table
      v-model="selected"
      :no-data-text="`${!appId ? 'Keine App ausgewählt' : 'Keine Einträge gefunden, die der Auswahl entsprechen'}`"
      select-strategy="all"
      :headers="headers as any"
      :items="items"
      items-per-page-text="Einträge je Seite"
      :items-per-page-options="[
        { value: 25, title: '25' },
        { value: 100, title: '100' },
        { value: -1, title: 'Alle' },
      ]"
      :items-per-page="25"
      show-select
      class="text-data-table"
    >
      <template #top>
        <div class="table-top">
          <v-text-field v-model="search" clearable class="search-field" prepend-inner-icon="mdi-magnify" />
        </div>
      </template>
      <template #[`item.data-table-do-not-translate`]="{ item }">
        <div v-if="item.excludeFromTranslation" class="exclude-mark">X</div>
      </template>
      <template #[`item.data-table-edit`]="{ item }">
        <v-btn class="edit-icon" variant="plain" @click="showEditDialog(deepClone()(item))"
          ><v-icon color="primary">mdi-pencil</v-icon></v-btn
        >
      </template>
      <template #[slotSource]="{ item }"
        ><span :class="{ touched: !!item.touched }">{{ item[props.sourceColKey] }}</span></template
      >
      <template #[slotTarget]="{ item }"
        ><span :class="{ touched: !!item.touched }">{{ item[props.targetColKey] }}</span></template
      >
      <template v-if="isOpdSelected" #[`item.usage`]="{ item }">
        <div v-if="item.usedIn">
          <div v-for="[resource, ids] in Object.entries(item.usedIn)" :key="`usage-location-${resource}`">
            <span class="usage-location">{{ `${resource}: ` }}</span>
            <a
              v-for="i in ids"
              :key="resource + i"
              class="usage-id"
              @click.prevent="openResource(resource as USAGE_LOCATION, i)"
            >
              {{ `#${i}` }}
            </a>
          </div>
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="isEditDialogShown" max-width="800px">
      <v-card color="lightgray">
        <v-card-title> Bezeichner "{{ editData.id }}" bearbeiten</v-card-title>
        <v-card-text>
          <v-switch v-model="editData.excludeFromTranslation" label="NICHT übersetzen" />
          <v-textarea v-model="editData[props.sourceColKey]" :autofocus="true" :label="props.sourceColKey" />
          <v-textarea v-model="editData[props.targetColKey]" :label="targetColKey" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="isEditDialogShown = false">Abbrechen</v-btn>
          <v-btn variant="elevated" color="primary" @click="saveEdit">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped lang="scss">
.search-field {
  width: 30rem;
}

::v-deep(.touched) {
  font-weight: bold;
}

::v-deep(:not(.text-primary).v-switch__thumb) {
  color: var(--item-petrol);
  opacity: 75%;
}

::v-deep(:not(.text-primary) > .v-switch__track) {
  color: lightgray;
}

::v-deep(.v-data-table-rows-no-data) {
  height: 20vh;
  color: var(--item-red);
}

.table-top {
  padding: 15px;
  display: flex;
  gap: 40px;
  justify-content: start;
  align-items: center;

  & > * {
    flex-grow: 0;
  }
}

.text-data-table {
  margin-bottom: 20px;

  .exclude-mark {
    color: var(--item-red);
    width: 70%;
    text-align: center;
  }

  .edit-icon {
    color: var(--item-petrol);
    font-size: 120%;
  }

  ::v-deep(.v-data-table-footer__pagination .v-icon) {
    color: var(--item-petrol) !important;
  }

  ::v-deep(.v-select) {
    width: 82px !important;
  }

  .usage-location {
    display: inline-block;
    width: 6.5rem;
  }

  .usage-id {
    color: var(--item-petrol);
    cursor: pointer;
  }

  .usage-id + .usage-id:before {
    content: ', ';
  }
}
</style>
