import Keycloak from 'keycloak-js';
import { ref } from 'vue';
import { KeycloakSynchronizer } from '@item-ind/keycloak-synchronizer';

const TOKEN_VALIDITY_MIN_20 = 280;

const initOptions = {
  url: import.meta.env.VITE_AUTH_SERVER_URL,
  realm: 'item',
  clientId: 'translator',
};

const keycloak = new Keycloak(initOptions);

new KeycloakSynchronizer(keycloak);
keycloak.onTokenExpired = () => {
  keycloak
    .updateToken(TOKEN_VALIDITY_MIN_20)
    .then()
    .catch(() => {
      console.error('Failed to refresh token');
    });
};

const success = ref(await keycloak.init({ onLoad: 'login-required', checkLoginIframe: false }));

export const useKeycloak = () => (success.value ? { token: keycloak.token } : { token: undefined });
