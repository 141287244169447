<script setup lang="ts">
import textLocalization from '@/apiConnections/text-localization.js';
import { MESSAGE_TYPE, MessageService } from '@/shared/message-service';
import type { LANGUAGE_KEY } from '@/types/localization';
import { computed, ref } from 'vue';

const props = defineProps<{
  appId: string | null;
  disabled: boolean;
  sourceLanguageKey: LANGUAGE_KEY;
  targetLanguageKey: LANGUAGE_KEY;
}>();

const emit = defineEmits(['createdNewItem']);

const isDialogShown = ref(false);

// computed value because destructuring props on top level will make them lose reactivity
const sourceKey = computed(() => props.sourceLanguageKey);
const targetKey = computed(() => props.targetLanguageKey);

const newItem = ref({
  id: '',
  [sourceKey.value]: '',
  [targetKey.value]: '',
  excludeFromTranslation: false,
});

async function saveElement() {
  const localization = {
    [sourceKey.value]: newItem.value[sourceKey.value],
    [targetKey.value]: newItem.value[targetKey.value],
  };
  if (newItem.value.excludeFromTranslation) localization.excludeFromTranslation = newItem.value.excludeFromTranslation;

  const messageService = MessageService.getInstance();

  if (newItem.value.id.trim().length) {
    await textLocalization
      .addSubDoc(`${props.appId}/${newItem.value.id}`, localization)
      .then(() => {
        messageService.add({
          text: 'Erfolgreich neuen Bezeichner erstellt.',
          type: MESSAGE_TYPE.INFO,
        });
        emit('createdNewItem', newItem.value.id);
      })
      .catch((error) => {
        messageService.add({
          text: `Hinzufügen fehlgeschlagen - der text-localization-service hat einen Fehler zurückgegeben: ${error}`,
          type: MESSAGE_TYPE.ERROR,
        });
      });
  } else {
    messageService.add({
      text: `Hinzufügen fehlgeschlagen - ungültige Language-ID!`,
      type: MESSAGE_TYPE.ERROR,
    });
  }
  isDialogShown.value = false;
}

const rules = {
  required: () => !!newItem.value.id.trim().length || 'Language-ID ist erforderlich!',
};
</script>

<template>
  <v-dialog v-model="isDialogShown" max-width="800px">
    <template #activator="{ props: activatorProps }">
      <v-btn v-bind="activatorProps" :disabled="disabled">Textelement erstellen</v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Bezeichner hinzufügen</span>
      </v-card-title>

      <v-card-text>
        <v-text-field v-model="newItem.id" label="Language-ID *" :rules="[rules.required]"></v-text-field>
        <v-switch v-model="newItem.excludeFromTranslation" label="NICHT übersetzen" />
        <v-textarea v-model="newItem[sourceKey]" :label="sourceKey"></v-textarea>
        <v-textarea v-model="newItem[targetKey]" :label="targetKey"></v-textarea>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="isDialogShown = false">Abbrechen</v-btn>
        <v-btn variant="elevated" :disabled="!newItem.id.trim().length" @click="saveElement">Speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss"></style>
