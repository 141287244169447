import type { Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import { uid } from 'uid';

export enum MESSAGE_TYPE {
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
}

const messageDefaultTitle: Readonly<Record<MESSAGE_TYPE, string>> = {
  warning: 'Warnung',
  error: 'Fehler',
  info: 'Hinweis',
  success: 'Erfolg',
};

export type Message = {
  id: string;
  text: string;
  type: MESSAGE_TYPE;
  title: string;
  timeout?: number;
};

export class MessageService {
  private static _instance: MessageService;
  private _messageSubject = new Subject<Message>();
  private subscriptions: Map<string, Subscription> = new Map();

  private constructor() {}

  public static getInstance() {
    if (!MessageService._instance) {
      MessageService._instance = new MessageService();
    }

    return MessageService._instance;
  }

  public add(newMessage: Pick<Message, 'text'> & Partial<Message>) {
    if (!newMessage.text) {
      return;
    }

    const type = newMessage.type || MESSAGE_TYPE.WARNING;
    const message: Message = {
      type,
      title: messageDefaultTitle[type],
      timeout: 15,
      ...newMessage,
      id: uid(),
    };

    this._messageSubject.next(message);
  }

  public subscribe(callback: (message: Message) => void) {
    const sub = this._messageSubject.subscribe(callback);
    const id = uid();
    this.subscriptions.set(id, sub);
    const unsubscribe = () => {
      sub.unsubscribe();
      this.subscriptions.delete(id);
    };
    return { unsubscribe };
  }
}
