<script setup lang="ts">
import textLocalization from '@/apiConnections/text-localization.js';
import { MESSAGE_TYPE, MessageService } from '@/shared/message-service';
import { type Ref, ref } from 'vue';

const props = defineProps<{
  appId: string | null;
}>();

const emit = defineEmits(['update:appId']);

const appOptions: Ref<string[]> = ref([]);
await textLocalization
  .getAppIdList()
  .then((appIdList) => {
    appOptions.value = appIdList;
  })
  .catch((error) => {
    MessageService.getInstance().add({
      text: `Konnte App-Liste nicht laden - der text-localization-service hat einen Fehler zurückgegeben: ${error}`,
      type: MESSAGE_TYPE.ERROR,
    });
  });
</script>

<template>
  <v-select
    :model-value="props.appId"
    :items="appOptions"
    label="App ID"
    class="app-id-select"
    @update:model-value="emit('update:appId', $event)"
  />
</template>

<style scoped lang="scss">
.app-id-select {
  width: 1rem;
}
</style>
