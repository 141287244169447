<script setup lang="ts">
import type { TimedMessage } from '@/components/ToastMessages/toast-messages';
import { ref } from 'vue';

const props = defineProps<{
  message: TimedMessage;
}>();

const counter = ref(0);
setInterval(() => {
  counter.value++;
}, 1000);

function countDown({ start, end }: TimedMessage) {
  if (!start || !end) {
    return;
  }

  const loadingDateInMs = start.getTime();
  const nowDateInMs = new Date().getTime();
  const secondDateInMs = end.getTime();

  const differenceBtwDates = secondDateInMs - nowDateInMs;
  const differenceBtwLoadingDate = secondDateInMs - loadingDateInMs;

  const secondsDiff = Math.round(differenceBtwDates / 1000);
  const secondsDiffLoading = Math.round(differenceBtwLoadingDate / 1000);
  const secondsPercent = 100 * (secondsDiff / secondsDiffLoading);

  return !isFinite(secondsPercent) ? 100 : secondsPercent;
}
</script>

<template>
  <div class="progress-wrapper">
    <v-progress-circular
      v-if="props.message.end"
      :key="counter"
      :model-value="countDown(props.message)"
      :rotate="360"
      :size="30"
      :width="15"
      :color="props.message.type"
      class="progress-countdown"
      bg-color="transparent"
    />
    <v-icon v-else :color="props.message.type" icon="mdi-close"></v-icon>
  </div>
</template>

<style lang="scss" scoped>
.progress-wrapper {
  z-index: 100;
  cursor: pointer;

  .progress-countdown {
    transform: rotateY(180deg);
  }
}
</style>
