import { LANGUAGE_KEY } from '@/types/localization.js';

export enum LOCALE {
  GERMAN = 'de-DE',
  ENGLISH = 'en-GB',
  ITALIAN = 'it-IT',
  FRENCH = 'fr-FR',
  SPANISH = 'es-ES',
  CHINESE = 'zh-CN',
  POLISH = 'pl-PL',
  CZECH = 'cs-CZ',
}
export const LOCALE_TO_KEY = {
  [LOCALE.GERMAN]: LANGUAGE_KEY.GERMAN,
  [LOCALE.ENGLISH]: LANGUAGE_KEY.ENGLISH,
  [LOCALE.ITALIAN]: LANGUAGE_KEY.ITALIAN,
  [LOCALE.FRENCH]: LANGUAGE_KEY.FRENCH,
  [LOCALE.POLISH]: LANGUAGE_KEY.POLISH,
  [LOCALE.CHINESE]: LANGUAGE_KEY.CHINESE,
  [LOCALE.SPANISH]: LANGUAGE_KEY.SPANISH,
  [LOCALE.CZECH]: LANGUAGE_KEY.CZECH,
};

export const languageOptions = [
  {
    id: LOCALE.GERMAN,
    label: 'Deutsch',
  },
  {
    id: LOCALE.ENGLISH,
    label: 'Englisch',
  },
  {
    id: LOCALE.ITALIAN,
    label: 'Italienisch',
  },
  {
    id: LOCALE.FRENCH,
    label: 'Französisch',
  },
  {
    id: LOCALE.SPANISH,
    label: 'Spanisch',
  },
  {
    id: LOCALE.CHINESE,
    label: 'Chinesisch',
  },
  {
    id: LOCALE.POLISH,
    label: 'Polnisch',
  },
  {
    id: LOCALE.CZECH,
    label: 'Tschechisch',
  },
] as const;

export type LanguageOption = (typeof languageOptions)[number];
