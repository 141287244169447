import type { Message } from '@/shared/message-service';
import { MessageService } from '@/shared/message-service';

export type TimedMessage = Message & { start?: Date; end?: Date };
export type ToastMessagesMap = Map<string, TimedMessage>;

export function initToastMessageSubscription(toastMessages: ToastMessagesMap) {
  return MessageService.getInstance().subscribe((message: TimedMessage) => {
    if (message.timeout) {
      message.start = new Date();

      const nowDate = new Date();
      nowDate.setSeconds(nowDate.getSeconds() + message.timeout);
      message.end = nowDate;

      setTimeout(() => {
        toastMessages.delete(message.id);
      }, message.timeout * 1000);
    }

    toastMessages.set(message.id, message);
  });
}
