<script setup lang="ts">
import textLocalization from '@/apiConnections/text-localization';
import { MESSAGE_TYPE, MessageService } from '@/shared/message-service';
import { type Ref, ref } from 'vue';

const props = defineProps<{
  disabled: boolean;
}>();

const emit = defineEmits(['fileUploaded']);

const files: Ref<File[] | undefined> = ref(undefined);

function openFileSelection() {
  (document.querySelector('#file-uploader') as HTMLInputElement).click();
}

async function startFileUpload() {
  if (!files.value) throw new Error('No file selected.');

  const messageService = MessageService.getInstance();

  const reader = new FileReader();
  reader.onload = async () => {
    await textLocalization
      .updateFromXML(reader.result)
      .then(() => {
        files.value = undefined;
        messageService.add({
          text: 'Daten erfolgreich aus XLF importiert.',
          type: MESSAGE_TYPE.INFO,
        });
        emit('fileUploaded');
      })
      .catch((error) => {
        messageService.add({
          text: `Update fehlgeschlagen - der text-localization-service hat einen Fehler zurückgegeben: ${error}`,
          type: MESSAGE_TYPE.ERROR,
        });
      });
  };

  reader.readAsArrayBuffer(files.value[0]);
}
</script>

<template>
  <div>
    <v-btn :disabled="props.disabled" @click="openFileSelection"
      ><v-icon>mdi-cloud-upload-outline</v-icon>&nbsp;Import XLF</v-btn
    >
    <v-file-input
      id="file-uploader"
      v-model="files"
      class="file-input"
      accept="application/xliff+xml"
      @change="startFileUpload"
    ></v-file-input>
  </div>
</template>

<style scoped lang="scss">
.file-input {
  display: none;
}
</style>
