import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { VDataTable } from 'vuetify/labs/VDataTable';
import { VSkeletonLoader } from 'vuetify/labs/VSkeletonLoader';

export default createVuetify({
  components: { VDataTable, VSkeletonLoader },
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#0582AB',
          secondary: '#5CBBF6',
        },
      },
    },
  },
  defaults: {
    global: {
      color: 'primary',
    },
    VBtn: {
      depressed: true,
      VIcon: {
        color: 'white',
      },
    },
    VCard: {
      color: 'lightgrey',
    },
    VSkeletonLoader: {
      color: 'lightgrey',
    },
    VSelect: {
      style: 'width: 20rem;',
      variant: 'solo',
    },
  },
});
